import { Alert, Button, Card, CardBody, Col, Table, Container, Row, Spinner } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { useCallback, useEffect, useState } from "react";
import {useParams } from "react-router-dom";
import { format } from "date-fns";
import { listReportsCampaign, listReportsCampaignByRoot, listReportsDetailedCampaign, listReportsDetailedCampaignByRoot } from "../../../Service/reports";
import Chart from "react-google-charts";
import { SMSReportStatusEnum } from "../../../Enums/sms-report.status.enum";
import { Badges } from "../../../AbstractElements";
import { viewCampaign } from "../../../Service/campaign";
import { FaDownload } from "react-icons/fa";
import { downloadCampaignMessages, downloadNotDelivered } from "../../../Service/export-reports";

const SmsReportsDetailsCampaign = () => {
  const { t } = useTranslation();

  const [campaignData, setCampaignData] = useState<any>();

  const mainTitle = `${t("reports_detailed")} - ${campaignData?.name ?? ""} (${campaignData?.identifier ?? ""})`;
  
  const base = `${process.env.PUBLIC_URL}/campaigns`;

  const { campaign_identifier, company_identifier } = useParams<{ campaign_identifier: string, company_identifier: string }>();

  const userData = localStorage.getItem("M2C:userData");
  
  const companyType = userData ? JSON.parse(userData)?.userInfo?.company_type : null;

  const [detailedData, setDetailedData] = useState<any>([]);

  const [graphData, setGraphData] = useState<any>();

  const [statusTableData, setStatusTableData] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(true);

  const [totalRows, setTotalRows] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);

  const [failMotive, setFailMotive] = useState("");

  const [loadingDownload, setLoadingDownload] = useState(false);

  const [loadingDownloadMessageReport, setLoadingDownloadMessageReport] = useState(false);

  const [allDelivered, setAllDelivered] = useState(true);

  const perPage = 50;

  const handleCampaign = async () => {
    try {
      setIsLoading(true);

      if (!campaign_identifier || !company_identifier) {
        return;
      }

      const response = await viewCampaign(campaign_identifier, company_identifier);

      console.log("response", response);

      const formattedFailMotive = response?.fail_motive ? response?.fail_motive?.replace(/\s+/g, '_') : "general_fail";

      console.log('formattedFailMotive', formattedFailMotive);

      const failMotive = t(formattedFailMotive);

      console.log('failMotive', failMotive);

      setFailMotive(failMotive);
      
      setCampaignData(response);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleDownload = async () => {
    setLoadingDownload(true);
    try {
      if (!campaign_identifier || !company_identifier) {
        return;
      }

      const blob = await downloadNotDelivered(campaign_identifier, company_identifier);

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", "not_delivered_recipients.txt");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Erro ao baixar o arquivo:", error);
    } finally {
      setLoadingDownload(false);
    }
  };

  const handleDownloadMessageReport = async () => {
    setLoadingDownloadMessageReport(true);
    try {
      if (!campaign_identifier || !company_identifier) {
        return;
      }

      const blob = await downloadCampaignMessages(campaign_identifier, company_identifier);

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", `campaign_${campaign_identifier}_report.txt`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Erro ao baixar o arquivo:", error);
    } finally {
      setLoadingDownloadMessageReport(false);
    }
  };


  const StatusChip = ({ status }: any) => {
    switch (status) {
      case SMSReportStatusEnum.DELIVERED:
        return (
          <Badges className="graph-color-2 btn" color="transparant">
            {t("Delivered")}
          </Badges>
        );

      case SMSReportStatusEnum.SENT_TO_OPERATOR:
      case SMSReportStatusEnum.QUEUED:
      case SMSReportStatusEnum.PROCESSING:
      case SMSReportStatusEnum.PENDING:
        return (
          <Badges className="graph-color-4 btn" color="transparant">
            {t("sent_operator")}
          </Badges>
        );
        
      default:
        return (
          <Badges className="graph-color-5 btn" color="transparant">
            {t("Undelivered")}
          </Badges>
        );
    }
  };

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      let response: any = [];

      if (companyType && companyType === "owner") {
        response = await listReportsCampaignByRoot(company_identifier, campaign_identifier);
      } else {
        response = await listReportsCampaign(company_identifier, campaign_identifier);
      }

      handleCampaign();

      setGraphData([
        ["Status", "Quantity"],
        [t("Delivered"), response.totalDelivered ?? 0],
        [t("Undelivered"), response.totalUndelivered ?? 0],
        [t("sent_operator"), response.totalSent ?? 0],
      ])

      if (response.totalUndelivered > 0) {
        setAllDelivered(false);
      }

      setStatusTableData([
        {
          status: t("sent_operator"),
          quantity: response.totalSent,
        },
        {
          status: t("Delivered"),
          quantity: response.totalDelivered,
        },
        {
          status: t("Undelivered"),
          quantity: response.totalUndelivered,
        },
      ]);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setIsLoading(false);
    }

  }, [campaign_identifier, company_identifier]);

  const reviewColumns = [
    {
      name: t("Status"),
      selector: (row: any) => `${row.status}`,
      sortable: true,
    },
    {
      name: t("quantity"),
      selector: (row: any) => `${row.quantity}`,
      sortable: true,
    },
  ];

  const columns = [
    {
      name: t("phone_number"),
      selector: (row: any) => `${row.recipient}`,
      sortable: true,
    },
    {
      name: t("status"),
      selector: (row: any) => row.status,
      cell: (row: any) => <StatusChip status={row.status} />,
      sortable: true,
    },
    {
      name: t("submit_date"),
      selector: (row: any) => `${row.created_at ? format(row.created_at, 'yyyy-MM-dd HH:mm') : ''}`,
      sortable: true,
    },
    {
      name: t("done_date"),
      selector: (row: any) => `${row.updated_at ? format(row.updated_at, 'yyyy-MM-dd HH:mm') : ''}`,
      sortable: true,
    },
    {
      name: t("cost"),
      selector: (row: any) => `${row.cost}`,
      sortable: true,
    },
    {
      name: t("service"),
      selector: (row: any) => `${row.service}`,
      sortable: true,
    },
    {
      name: t("identifier"),
      selector: (row: any) => `${row.identifier}`,
      sortable: true,
    },
    {
      name: t("message"),
      selector: (row: any) => `${row.message}`,
      sortable: true,
    },
  ];

  // $graph-color-4: #f6c761;
  // $graph-color-2: #61AE41;
  // $graph-color-5: #e61010;

  // Entregue, não entregue e enviado operadora

  const graphOptions = {
    is3D: true,
    colors: [
      "#61AE41", 
      "#E61010",
      "#F6C761",
    ],
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleDetailedData = async (currentPage: number, perPage: number) => {
    try {
      setIsLoading(true);
      let response: any = [];

      if (companyType && companyType === "owner") {
        response = await listReportsDetailedCampaignByRoot(company_identifier, campaign_identifier);
      } else {
        response = await listReportsDetailedCampaign(company_identifier, campaign_identifier, currentPage, perPage);
      }

      setDetailedData(response.data);
      setTotalRows(response.total);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    handleCampaign();
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    handleDetailedData(currentPage, perPage);
  }, [currentPage]);

  return (
    <>
      <Breadcrumbs
        base={base}
        mainTitle={mainTitle}
        parent={t("campaigns")}
      />
      <Container fluid>
        {isLoading ? (
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="d-flex flex-column align-items-center">
                  <div
                    className="spinner-border text-primary position-relative"
                    role="status"
                    style={{
                      width: '10rem',
                      height: '10rem',
                      borderWidth: '0.65rem',
                    }}
                  >
                  </div>
                  <span
                      className="position-absolute top-50 start-50 translate-middle"
                      style={{
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        color: 'var(--bs-primary)',
                      }}
                    >
                      {t("loading")}
                    </span>
                </div>
              </CardBody>
            </Card>
          </Col>        
        ) : (
          <>
            {campaignData?.status === "error" && (
              <Row>
                <Col sm="12">
                  <Card className="mb-4 shadow-sm">
                    <CardBody>
                      <Alert color="danger" className="d-flex align-items-center">
                        <i className="bi bi-exclamation-triangle-fill me-2"></i>
                        {t('error')}: {failMotive}
                      </Alert>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            {campaignData?.status === "insufficient_balance" && (
              <Row>
                <Col sm="12">
                  <Card className="mb-4 shadow-sm">
                    <CardBody>
                      <Alert color="info" className="d-flex align-items-center">
                        <i className="bi bi-exclamation-triangle-fill me-2"></i>
                        {t('error')}: {t('insufficient_balance')}
                      </Alert>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            <Row>
              {!graphData && (
                <Col sm="12">
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-center">
                        <div className="alert alert-warning" role="alert">
                          {t("no_data")}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              {graphData && (
                <Col sm="12">
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <Col sm="5">
                          <div className="table-responsive">
                            <DataTable
                              columns={reviewColumns}
                              data={statusTableData}
                              highlightOnHover
                              striped
                            />
                          </div>
                        </Col>
                        <Col sm="7">
                          <div> 
                            <Chart
                              chartType="PieChart"
                              data={graphData}
                              options={graphOptions}
                              width={"100%"}
                              height={"400px"}
                            />
                          </div>
                        </Col>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>

            <Row>
              <Col sm="12">
                <Card className="shadow border-0">
                  <CardBody>
                    <h5 className="fw-bold mb-3">{t('download_campaign_reports')}</h5>

                    <div className="d-flex flex-column gap-3">
                      {!allDelivered && (
                        <div className="d-flex justify-content-between align-items-center p-3 rounded bg-light w-100">
                          <span className="fw-bold text-dark">{t('not_delivered_report_numbers')}:</span>
                          <Button variant="primary" onClick={handleDownload} disabled={loadingDownload}>
                            {loadingDownload ? <Spinner animation="border" size="sm" /> : <FaDownload />}
                            {' '}
                            {t('download')}
                          </Button>
                        </div>
                      )}

                      {allDelivered && (
                        <div className="d-flex justify-content-between align-items-center p-3 rounded bg-light w-100">
                          <span className="fw-bold text-dark">{t('campaign_message_report')}:</span>
                          <Button variant="primary" onClick={handleDownloadMessageReport} disabled={loadingDownloadMessageReport}>
                            {loadingDownloadMessageReport ? <Spinner animation="border" size="sm" /> : <FaDownload />}
                            {' '}
                            {t('download')}
                          </Button>
                        </div>
                      )}

                      <div className="d-flex justify-content-between align-items-center p-3 rounded bg-light w-100">
                        <span className="fw-bold text-dark">{t('campaign_message_report')}:</span>
                        <Button variant="primary" onClick={handleDownloadMessageReport} disabled={loadingDownloadMessageReport}>
                          {loadingDownloadMessageReport ? <Spinner animation="border" size="sm" /> : <FaDownload />}
                          {' '}
                          {t('download')}
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              {detailedData && detailedData.length > 0 && (
                <Col sm="12">
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                      <DataTable
                        columns={columns}
                        data={detailedData}
                        progressPending={isLoading}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        paginationRowsPerPageOptions={[50]}
                        paginationPerPage={perPage}
                        paginationDefaultPage={currentPage}
                        subHeader
                        highlightOnHover
                        striped
                        persistTableHead
                      />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default SmsReportsDetailsCampaign;
