import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: {
          queue_server_problem: "There was a problem with the line or line disabled. Please contact support.",
          no_contacts_found: "No contacts found",
          click_to_upload_file: "Click here to select a file to upload.",
          selected_upload_file: "Selected file to upload:",
          upload_file_accepted_format: "Accepted formats: .txt, .csv (max 3MB)",
          gateway_not_found: "There was a problem with the line or line disabled. Please contact support.",
          invalid_campaign_state: "General error to processing campaign",
          error_processing_campaign: "General error to processing campaign",
          message_not_configured_in_campaign: "Message not configured in campaign",
          service_not_configured_in_campaign: "There was a problem with the line or line disabled. Please contact support.",
          company_not_found: "General error to processing campaign",
          service_not_found: "There was a problem with the line or line disabled. Please contact support.",
          service_inactive:"There was a problem with the line or line disabled. Please contact support.",
          cost_not_configured_to_campaign: "There was a problem with the line or line disabled. Please contact support.",
          gateway_and_service_not_found: "There was a problem with the line or line disabled. Please contact support.",
          gateway_inactive: "There was a problem with the line or line disabled. Please contact support.",
          no_gateway_code_configured: "There was a problem with the line or line disabled. Please contact support.",
          gateway_not_found_for_service: "There was a problem with the line or line disabled. Please contact support.",
          failed_to_create_queue_batch: "General error to processing campaign",
          queue_batch_not_found: "General error to processing campaign",
          failed_to_retrieve_balance: "General error to processing campaign",
          insufficient_balance: "Insufficient Balance",
          insufficient_balance_for_campaign_sending: "Insufficient Balance",
          campaign_processing_error: "General error to processing campaign",
          body_not_informed: "General error to processing campaign",
          schedule_date_or_time_or_timezone_not_informed: "Schedule date invalid",
          invalid_contact_file: "Invalid contact file",
          invalid_contact_file_extension: "Invalid contact file extension",
          no_data: "No data",
          not_delivered_report_numbers: "Not delivered report numbers",
          campaign_message_report: "Campaign message report",
          download_campaign_reports: "Download campaign reports",
          write_email:
            "Enter your email so we can send you the code to reset your password",
          monthly_messages_sent: "Monthly messages sent",
          year_monthly_messages: "Yearly: messages sent",
          sms_monthly_spend: "Monthly SMS spend",
          daily_cost: "Daily cost",
          monthly_cost: "Monthly cost",
          yearly_cost: "Yearly cost",
          preparing: "Preparing",
          forbidden: "Forbidden",
          sent_operator: "Sent Operator",
          limit_contacts_per_campaign: "Limit contacts per campaign: 50.000 contacts (50k)",
          daily_delivered_not_delivered: "Today: Delivered x Undelivered",
          daily_sms_status: "Today: SMS status",
          daily_sms_total_other_states: "Today: Total SMS other status",
          daily_sms_total_delivered: "Today: Total SMS delivered",
          daily_sms_total_sent: "Today: Total SMS sent",
          year_monthly_messages_cost: "Yearly: messages cost",
          view: "View",
          duplicate: "Duplicate",
          view_service: "View Service",
          schedule_date: "Schedule date",
          schedule_time: "Schedule time (HH:MM)",
          schedule_date_placeholder: "Enter the schedule date",
          schedule_time_placeholder: "Enter the schedule time",
          alert_confirm_text_gateway_error_active: "Gateway is active",
          error_user_already_exists: "User already exists",
          NotSent: "Not sent",
          scheduled: "Scheduled",
          recipient: "Recipient",
          success_schedule_campaign: "Campaign successfully scheduled!",
          error_loading_user_data: "Error loading user data",
          debit_by_use: "Debit by use",
          error: "Error",
          sms_type: "SMS Type",
          sms_type_text: "The format of dispatch for your SMS.",
          campaign_service_text: "The service your campaign will use.",
          recipient_text: "The recipient of your campaign.",
          scheduling: "Scheduling",
          scheduling_text: "If you want to schedule the sending of your campaign.",
          message_content: "Message content",
          message_content_text: "Create and view your SMS message content.",
          owner: "Owner",
          credit_text: "Credits and Debits List",
          subtype: "Subtype",
          credit_transfer: "Credit Transfer",
          add_credit_text: "Add credits",
          deposit: "Deposit",
          select_period: "Select period",
          pos_paid_text: "List of POS paid",
          period: "Period",
          status: "Status",
          close_date: "Close date",
          delayed_payment_days: "Delayed payment days",
          pos_paid: "POS paid",
          use_api: "Use API",
          service_to_api: "Service for API",
          report: "Report",
          open: "Open",
          paid: "Paid",
          yes: "Yes",
          no: "No",
          Jan: "Jan", 
          Feb: "Feb", 
          Mar: "Mar", 
          Apr: "Apr", 
          May: "May", 
          Jun: "Jun", 
          Jul: "Jul", 
          Aug: "Aug", 
          Sep: "Sep", 
          Oct: "Oct", 
          Nov: "Nov", 
          Dec: "Dec",
          total: "Total",
          cancel: "Cancel",
          login1: "Code sent to email",
          login1_error: "Error sending code to email",
          login2: "Password reset successfully",
          login2_error: "Error resetting password",
          code: "Code",
          confirm_password: "Confirm password",
          reset_password: "Reset password",
          edit_user_text: "Edit user",
          add_user_text: "Add user",
          asc: "Ascending",
          desc: "Descending",
          credit: "Credit",
          debit: "Debit",
          direction: "Direction",
          Status: "Status",
          document: "Document",
          submit_date: "Sent date",
          done_date: "Read date",
          document_placeholder_user: "Enter user's CPF",
          total_messages: "Total messages",
          created_at: "Creation date",
          company_name: "Company name",
          error_saved: "Error saving",
          success_saved: "Successfully saved",
          add_credits: "Add credits",
          amount: "Amount",
          description: "Description",
          error_campaign_list: "Pending list",
          alert_confirm_text_contact_error: "Error saving contact",
          date_in: "Entry Date",
          date_out: "Valid Date",
          uploaded_file: "Uploaded file",
          file_ready_to_upload: "File ready to upload!",
          Financial: "Financial",
          transactions: "Transactions",
          balance: "Balance",
          success_contact: "Contact saved successfully!",
          error_contact: "Error saving contact",
          error_contact_list: "Error saving contact list",
          characters: "characters",
          alert_confirm_text_contact_list: "Contact list successfully deleted!",
          alert_confirm_text_contact_list_error: "Error deleting contact list",
          additional_document_label: "Additional document",
          error_company: "Error saving company",
          additional_document_placeholder: "Enter CPF",
          service: "Service",
          balancePos: "Postpaid Balance",
          balancePre: "Prepaid Balance",
          balancePostText: "The postpaid balance will always have a value of zero or negative. This balance will be charged in the next period.",
          balancePreText: "The prepaid balance will always have a zero or positive value. This balance will be used to send messages.",
          success_send_campaign: "Campaign sent successfully!",
          alert_confirm_text_campaing_error: "Error deleting",
          filter: "Filter",
          details: "Details",
          success_campaign: "Campaign saved successfully!",
          error_campaign: "Error saving campaign",
          error_campaign_get: "Error retrieving campaign",
          success_service: "Service saved successfully!",
          alert_confirm_text_service_error: "Error deleting service",
          error_service: "Error saving service",
          delivery_fee: "Delivery fee",
          batchdays_between_batchesmessages: "Days between batches",
          phone_number: "Phone number",
          save: "Save",
          days_between_batches: "Days between batches",
          gradual_increase: "Gradual increase %",
          batch_messages: "Messages per batch",
          send_in_batches: "Send in batches",
          now: "Now",
          later: "Later",
          success_contact_list: "Contact list saved successfully!",
          success_gateway: "Gateway saved successfully!",
          error_gateway: "Error saving gateway",
          alert_confirm_title_error: "Error deleting",
          alert_confirm_text_gateway_error: "Error deleting gateway",
          required_field: "Required field",
          others: "Others",
          payload: "Payload",
          "non-concatenated": "Non-concatenated",
          all: "All",
          "non-ported": "Non-ported",
          ported: "Ported",
          loading: "Loading...",
          success_company: "Company added successfully!",
          reseller: "Reseller",
          your_message_placeholder: "Type your message here",
          your_message: "Your message",
          model: "Model",
          personalized: "Personalized",
          add_files: "Add files",
          drag_in_drop_text: "Drag files or click to browse",
          send_to: "Send to",
          saved_list: "Saved list",
          new_list: "New list",
          block_label: "Do not send to (blacklist)",
          skip_label: "Skip duplicate contacts",
          numbers_placeholder:
            "Enter numbers separated by commas. E.g., 31912345678, 21912345678",
          numbers_label: "Numbers to send",
          sender_placeholder: "Enter sender name",
          sender: "Sender",
          sender_number: "Remetente (LA)",
          cost: "Custo",
          simple: "Simple",
          list: "List",
          select: "Select",
          info_text_list:
            "The List type allows selecting a contact list from the system or uploading a new file.",
          info_text_simple:
            "The Simple type allows entering one or more numbers to send, separated by commas.",
          reports: "Reports",
          campaign: "Campaign",
          campaigns: "Campaigns",
          Delivered: "Delivered",
          Undelivered: "Undelivered",
          Scheduled: "Scheduled",
          Rejected: "Rejected",
          Accepted: "Accepted",
          Unknown: "Unknown",
          InvalidNumber: "Invalid Number",
          Processing: "Processing",
          Waiting: "Sent to operator",
          Expired: "Expired",
          EnRoute: "Waiting by operator",
          reports_detailed: "Detailed Report",
          group_by_campaign: "Group by Campaign",
          name_label_campaign: "Campaign name",
          name_placeholder_campaign: "Enter campaign name",
          alert_confirm_text_campaing: "The campaign has been deleted!",
          initial_date: "Initial date",
          final_date: "Final date",
          initial_date_placeholder: "Enter the initial date",
          final_date_placeholder: "Enter the final date",
          add_campaign: "Add campaign",
          edit_campaign: "Edit campaign",
          schedule: "Schedule",
          recipients: "Recipients",
          handed_out: "Delivered",
          example_file: "Example file",
          download_file: "Download CSV file",
          credits: "Credits",
          charge_mo: "Charge for processed MO",
          fail_over: "Fail Over",
          operators: "Operators",
          concatenated_send: "Concatenated send",
          rule_for_concatenates: "Rule for concatenates",
          rule_for_portability: "Rule for portability",
          speed: "Speed",
          value: "Value",
          own_cost: "Own cost",
          password_gateway: "Password",
          user_gateway: "User",
          port: "Port",
          identifier: "Identifier",
          add_gateway: "+ Add gateway",
          add_gateway_text: "Add gateway",
          shipping_type: "Shipping type",
          server: "Server",
          alert_confirm_text_gateway: "The gateway has been deleted!",
          alert_confirm_text_service: "The service has been deleted!",
          rn1: "Use RN1 for billing",
          concatenated: "Concatenated",
          sms_service: "SMS service",
          block_free: "Block free shipments",
          alert_prepaid: "Please select if it's prepaid!",
          prepaid: "Prepaid",
          add_service: "+ Add service",
          add_service_text: "Add service",
          gateway: "Gateway",
          alert_confirm_text_contactlist: "The contact list has been deleted!",
          alert_confirm_text_blacklist: "The blacklist has been deleted!",
          submit: "Submit",
          var_off: "Hide variables",
          duplicity_label: "Remove duplicity",
          import_contact_label: "Contact file",
          import_contact_title: "Import contact",
          address: "Address",
          add_contact_title: "Add contact",
          edit_contact_title: "Edit contact",
          number_label: "Number",
          add_blacklist: "+ Add blacklist",
          edit_blacklist_title: "Edit blacklist",
          add_blacklist_title: "Add blacklist",
          alert_confirm_text_contact: "The contact has been deleted",
          add_contact: "Add contact",
          import_contact: "Import contact",
          edit_contactlist_title: "Edit contact list",
          numbers_procon: "Numbers blocked in procon",
          number: "Number",
          state: "State",
          creation_date: "Creation date",
          access: "Access",
          quantity: "Quantity",
          company_alert: "Please select a company.",
          tools: "Tools",
          contactlist: "Contact list",
          blacklist: "Black list",
          procon: "Procon",
          contacts: "Contacts",
          manage_users: "Manage users",
          clients: "Clients",
          user_name_label_user: "User Name",
          user_name_placeholder_user: "Enter your user name",
          password_label_user: "Password",
          password_placeholder_user: "Enter your password",
          email_label_user: "Email",
          email_placeholder_user: "Enter your email",
          phone_label_user: "Phone",
          phone_placeholder_user: "Enter your phone number",
          first_name_label_user: "First Name",
          first_name_placeholder_user: "Enter your first name",
          last_name_label_user: "Last Name",
          last_name_placeholder_user: "Enter your last name",
          type_label_user: "Type",
          active_label_user: "Active",
          user_name_alert_user: "Please enter a valid user name",
          password_alert_user: "Please enter a valid password",
          active_alert_user: "Please specify if active",
          email_alert_user: "Please enter a valid email",
          phone_alert_user: "Please provide a valid phone number",
          first_name_alert_user: "Please enter a valid first name",
          last_name_alert_user: "Please enter a valid last name",
          type_alert_user: "Please enter a valid type",
          username: "User name",
          email: "Email",
          company: "Company",
          type: "Type",
          active: "Active",
          actions: "Actions",
          resale: "Resale",
          client: "Client",
          branch: "Branch",
          inactive: "Inactive",
          cnpj_alert: "Please enter a valid CNPJ",
          name_alert: "Please enter a valid name",
          logo_alert: "Please upload a valid logo",
          logo_reduced_alert: "Please upload a valid reduced logo",
          type_alert: "Please provide a valid type",
          active_alert: "Please specify if active",
          corporate_reason_alert: "Please enter a valid corporate reason",
          processing: "Processing",
          paused: "Paused",
          pending: "Pending",
          sent: "Completed",
          state_registration_alert: "Please enter a valid state registration",
          address_alert: "Please provide a valid address",
          financial_phone_alert:
            "Please provide a valid financial phone number",
          technical_phone_alert:
            "Please provide a valid technical phone number",
          financial_email_alert: "Please provide a valid financial email",
          technical_email_alert: "Please provide a valid technical email",
          observations_alert: "Please enter your observations",
          name_label: "Name",
          cnpj_label: "CNPJ",
          logo_label: "Logo (480x140)",
          logo_reduced_label: "Reduced Logo (100x100)",
          corporate_reason_label: "Corporate Reason",
          state_registration_label: "State Registration",
          type_label: "Type",
          address_label: "Address",
          financial_phone_label: "Financial Phone",
          technical_phone_label: "Technical Phone",
          financial_email_label: "Financial Email",
          technical_email_label: "Technical Email",
          observations_label: "Observations",
          active_label: "Active",
          name_placeholder: "Enter your name",
          cnpj_placeholder: "Enter your CNPJ",
          corporate_reason_placeholder: "Enter corporate reason",
          state_registration_placeholder: "Enter state registration",
          address_placeholder: "Enter address",
          phone_placeholder: "Enter phone",
          financial_phone_placeholder: "Enter financial phone",
          technical_phone_placeholder: "Enter technical phone",
          financial_email_placeholder: "Enter financial email",
          technical_email_placeholder: "Enter technical email",
          observations_placeholder: "Enter your observations",
          account: "Account",
          settings: "Settings",
          logout: "Log Out",
          alert_title: "Are you sure?",
          alert_text: "You won't be able to revert this!",
          alert_button: "Yes, delete it!",
          alert_cancel: "Cancel",
          alert_confirm_title: "Deleted",
          alert_confirm_text: "The company has been deleted.",
          alert_confirm_text_user: "The user has been deleted!",
          add_company: "+ Add company",
          add_company_text: "Add company",
          inputs: "Inputs",
          outputs: "Outputs",
          balance_text: "Balance, inputs, outputs of companies",
          add_transaction: "+ Add transaction",
          add_transaction_text: "Add transaction",
          company_identifier: "Company",
          Company: "Company",
          success_transaction: "Transaction saved successfully!",
          edit: "Edit",
          delete: "Delete",
          add_user: "+ Add user",
          transactions_text: "Search, filter, add, edit or delete transactions",
          company_text: "Search, filter, add, edit or delete your customers",
          user_text: "Search, filter, add, edit or delete your users",
          name: "Name",
          top_company: "Top company",
          search: "Search",
          error_login: "Invalid email or password!",
          sig_in_text: "Sign in to account",
          enter_email: "Enter your email & password to login",
          email_addres: "Email Address",
          password: "Password",
          forgot_password: "Forgot password",
          sig_in: "Sign in",
          send: "Send",
          sms: "SMS",
          Configurations: "Configurations",
          Campaigns: "Campaigns",
          "Sending Single": "Sending Single",
          "Sending by File": "Sending by File",
          "Sending by Contact List": "Sending by Contact List",
          Messages: "Messages",
          Companies: "Companies",
          companies_add: "Company add",
          Services: "Services",
          General: "General",
          Dashboards: "Dashboards",
          Default: "Default",
          Project: "Project",
          Ecommerce: "Ecommerce",
          Education: "Education",
          Widgets: "Widgets",
          Chart: "Chart",
          Applications: "Applications",
          "Project List": "Project List",
          "Create New": "Create New",
          "File Manager": "File Manager",
          "Add Product": "Add Product",
          Product: "Product",
          "Product Page": "Product Page",
          "Product List": "Product List",
          "Payment Details": "Payment Details",
          "Order History": "Order History",
          Invoice: "Invoice",
          "Invoice-1": "Invoice-1",
          "Invoice-2": "Invoice-2",
          "Invoice-3": "Invoice-3",
          "Invoice-4": "Invoice-4",
          "Invoice-5": "Invoice-5",
          "Invoice-6": "Invoice-6",
          Cart: "Cart",
          Wishlist: "Wishlist",
          Checkout: "Checkout",
          Pricing: "Pricing",
          Chat: "Chat",
          Users: "Users",
          "Letter Box": "Letter Box",
          "Private Chat": "Private Chat",
          "Group Chat": "Group Chat",
          "User Profile": "User Profile",
          "User Edit": "User Edit",
          "User Cards": "User Cards",
          Bookmarks: "Bookmarks",
          Contacts: "Contacts",
          Task: "Task",
          Calendar: "Calendar",
          "Social App": "Social App",
          Todo: "Todo",
          "Search Result": "Search Result",
          "Form Validation": "Form Validation",
          "Base Inputs": "Base Inputs",
          "Checkbox & Radio": "Checkbox & Radio",
          "Input Groups": "Input Groups",
          "Input Mask": "Input Mask",
          "Mega Option": "Mega Option",
          Datepicker: "Datepicker",
          Touchspin: "Touchspin",
          Switch: "Switch",
          Forms: "Forms",
          "Form Controls": "Form Controls",
          "Forms & Table": "Forms & Table",
          Typeahead: "Typeahead",
          Clipboard: "Clipboard",
          "Form Widget": "Form Widget",
          "Form Wizard 1": "Form Wizard 1",
          "Form Wizard 2": "Form Wizard 2",
          "Two Factor": "Two Factor",
          "Form Layout": "Form Layout",
          Table: "Table",
          "Reactstrap Tables": "Reactstrap Tables",
          "Basic Tables": "Basic Tables",
          "Table Components": "Table Components",
          "Data Tables": "Data Tables",
          "Basic Init": "Basic Init",
          "Advance Init": "Advance Init",
          API: "API",
          "Data Source": "Data Source",
          Components: "Components",
          Typography: "Typography",
          Avatars: "Avatars",
          "Helper Classes": "Helper Classes",
          Grid: "Grid",
          "Tag & Pills": "Tag & Pills",
          Progress: "Progress",
          Modal: "Modal",
          Alert: "Alert",
          Popover: "Popover",
          Tooltip: "Tooltip",
          Dropdown: "Dropdown",
          Accordion: "Accordion",
          Tabs: "Tabs",
          Lists: "Lists",
          "Bonus-Ui": "Bonus-Ui",
          Scrollable: "Scrollable",
          "Tree View": "Tree View",
          Toasts: "Toasts",
          Rating: "Rating",
          Dropzone: "Dropzone",
          Tour: "Tour",
          SweetAlert2: "SweetAlert2",
          "Owl Carousel": "Owl Carousel",
          Ribbons: "Ribbons",
          Pagination: "Pagination",
          Breadcrumb: "Breadcrumb",
          "Range Slider": "Range Slider",
          "Image Cropper": "Image Cropper",
          "Basic Card": "Basic Card",
          "Creative Card": "Creative Card",
          Timeline: "Timeline",
          Icons: "Icons",
          "Flag Icon": "Flag Icon",
          "Fontawesome Icon": "Fontawesome Icon",
          "Ico Icon": "Ico Icon",
          "Themify Icon": "Themify Icon",
          "Feather Icon": "Feather Icon",
          "Weather Icon": "Weather Icon",
          Buttons: "Buttons",
          "Default Style": "Default Style",
          "Flat Style": "Flat Style",
          "Edge Style": "Edge Style",
          "Raised Style": "Raised Style",
          "Button Group": "Button Group",
          Charts: "Charts",
          "Apex Chart": "Apex Chart",
          "Google Chart": "Google Chart",
          "Chart JS Chart": "Chart JS Chart",
          Pages: "Pages",
          "Sample Page": "Sample Page",
          Others: "Others",
          "Error Pages": "Error Pages",
          "Error 400": "Error 400",
          "Error 401": "Error 401",
          "Error 403": "Error 403",
          "Error 404": "Error 404",
          "Error 500": "Error 500",
          "Error 503": "Error 503",
          "Login Simple": "Login Simple",
          "Login with Bg Image": "Login with bg Image",
          "Login With Image Two": "Login With Image Two",
          "Login With Validation": "Login With Validation",
          "Login With Tooltip": "Login With Tooltip",
          "Login With Sweetalert": "Login With Sweetalert",
          "Register Simple": "Register Simple",
          "Register With Bg Image": "Register With Bg Image",
          "Register With Bg Two": "Register With Bg Two",
          "Register Wizard": "Register Wizard",
          "Unlock User": "Unlock User",
          "Forget Password": "Forget Password",
          "Reset Password": "Reset Password",
          Maintenance: "Maintenance",
          "Coming Simple": "Coming Simple",
          "Coming With Bg Video": "Coming With Bg Video",
          "Coming With Bg Image": "Coming With Bg Image",
          Miscellaneous: "Miscellaneous",
          Gallery: "Gallery",
          "Gallery Grids": "Gallery Grids",
          "Gallery Grid Desc": "Gallery Grid Desc",
          "Masonry Gallery": "Masonry Gallery",
          "Masonry With Desc": "Masonry With Desc",
          "Hover Effect": "Hover Effect",
          Blog: "Blog",
          "Blog Details": "Blog Details",
          "Blog Single": "Blog Single",
          "Add Post": "Add Post",
          FAQ: "FAQ",
          "Job Search": "Job Search",
          "Cards View": "Cards View",
          "List View": "List View",
          "Job Detail": "Job Detail",
          Apply: "Apply",
          Learning: "Learning",
          "Learning List": "Learning List",
          "Detailed Course": "Detailed Course",
          Map: "Map",
          "Google Map": "Google Map",
          "Leaflet Map": "Leaflet Map",
          Editor: "Editor",
          "CK Editor": "CK Editor",
          "MDE Editor": "MDE Editor",
          "ACE Editor": "ACE Editor",
          Knowledgebase: "Knowledgebase",
          SupportTicket: "SupportTicket",
          Authentication: "Authentication",
          "Login With Bg Image": "Login With Bg Image",
          "Coming Soon": "Coming Soon",
          Pinned: "Pinned",
        },
      },
      cn: {
        translations: {
          queue_server_problem: "線路有問題或線路已停用 請聯絡支援人員",
          no_contacts_found: "沒有找到號碼",
          click_to_upload_file: "點擊此處選擇要上傳的文件",
          upload_file_accepted_format: "接受的格式：.txt、.csv（最大 3MB）",
          selected_upload_file: "選擇文件上傳",
          gateway_not_found: "線路有問題或線路已停用 請聯絡支援人員",
          invalid_campaign_state: "處理行銷活動時出現一般錯誤",
          error_processing_campaign: "處理行銷活動時出現一般錯誤",
          message_not_configured_in_campaign: "活動中未配置訊息",
          service_not_configured_in_campaign: "線路有問題或線路已停用 請聯絡支援人員",
          company_not_found: "處理行銷活動時出現一般錯誤",
          service_not_found: "線路有問題或線路已停用 請聯絡支援人員",
          service_inactive:"線路有問題或線路已停用 請聯絡支援人員",
          cost_not_configured_to_campaign: "線路有問題或線路已停用 請聯絡支援人員",
          gateway_and_service_not_found: "線路有問題或線路已停用 請聯絡支援人員",
          gateway_inactive: "線路有問題或線路已停用 請聯絡支援人員",
          no_gateway_code_configured: "線路有問題或線路已停用 請聯絡支援人員",
          gateway_not_found_for_service: "線路有問題或線路已停用 請聯絡支援人員",
          failed_to_create_queue_batch: "處理行銷活動時出現一般錯誤",
          queue_batch_not_found: "處理行銷活動時出現一般錯誤",
          failed_to_retrieve_balance: "處理行銷活動時出現一般錯誤",
          insufficient_balance: "余额不足",
          insufficient_balance_for_campaign_sending: "餘額不足",
          campaign_processing_error: "處理行銷活動時出現一般錯誤",
          body_not_informed: "處理行銷活動時出現一般錯誤",
          schedule_date_or_time_or_timezone_not_informed: "計劃日期無效",
          invalid_contact_file: "無效的聯絡文件",
          invalid_contact_file_extension: "聯絡人檔案副檔名無效",
          not_delivered_report_numbers: "未交付報告號碼",
          campaign_message_report: "活動訊息報告",
          download_campaign_reports: "下載活動報告",
          write_email: "输入您的电子邮件，以便我们发送密码重置代码",
          no_data: "没有数据",
          monthly_messages_sent: "每月發送的訊息",
          year_monthly_messages: "每年 - 發送的訊息",
          sms_monthly_spend: "每月簡訊支出",
          daily_cost: "每日費用",
          monthly_cost: "每月費用",
          yearly_cost: "每年費用",
          daily_delivered_not_delivered: "今天 - 已交付 x 未交付",
          daily_sms_status: "今天 - 簡訊狀態",
          daily_sms_total_other_states: "今天 - 簡訊總數 其他狀態",
          daily_sms_total_delivered: "今天 - 發送的短信總數",
          daily_sms_total_sent: "今天 - 發送的短信總數",
          year_monthly_messages_cost: "每年 - 訊息費用",
          preparing: "準備",
          forbidden: "禁止",
          sent_operator: "已發送的運營商",
          limit_contacts_per_campaign: "每個活動的聯繫人限制 -  50.000 聯絡方式 (50k)",
          view: "看法",
          duplicate: "重複",
          view_service: "查看服務",
          schedule_date: "預定日期",
          schedule_time: "安排時間 (HH:MM)",
          schedule_date_placeholder: "輸入計劃日期",
          schedule_time_placeholder: "輸入預定時間",
          NotSent: "未發送",
          recipient: "接收者",
          scheduled: "已计划",
          success_schedule_campaign: "活動已成功安排！",
          error_loading_user_data: "加載用戶數據時出錯",
          alert_confirm_text_gateway_error_active: "網關已啟用",
          error_user_already_exists: "用戶已存在",
          debit_by_use: "使用費用",
          error: "错误",
          sms_type: "簡訊類型",
          sms_type_text: "SMS 的發送格式",
          campaign_service_text: "您的廣告活動將使用的服務",
          recipient_text: "您的行銷活動的接收者",
          scheduling: "調度",
          scheduling_text: "如果您想安排發送行銷活動",
          message_content: "留言內容",
          message_content_text: "建立並查看您的簡訊",
          owner: "擁有者",
          credit_text: "貸項和借項清單",
          subtype: "亞型",
          credit_transfer: "學分轉移",
          add_credit_text: "新增製作人員",
          deposit: "訂金",
          select_period: "選擇期間",
          pos_paid_text: "後付費列表",
          period: "時期",
          status: "地位",
          close_date: "截止日期",
          delayed_payment_days: "延遲付款天數",
          pos_paid: "後付費",
          use_api: "使用應用程式介面",
          service_to_api: "應用程式介面服務",
          report: "報告",
          open: "打開",
          paid: "有薪資的",
          yes: "是的",
          no: "不",
          Jan: "熱內盧", 
          Feb: "二月", 
          Mar: "行進", 
          Apr: "四月", 
          May: "可能", 
          Jun: "六月", 
          Jul: "七月", 
          Aug: "八月", 
          Sep: "九月", 
          Oct: "十月", 
          Nov: "十一月", 
          Dec: "十二月",
          total: "全部的",
          cancel: "取消",
          login1: "代码已发送到电子邮件",
          login1_error: "发送代码到电子邮件时出错",
          login2: "密码重置成功",
          login2_error: "重置密码时出错",
          code: "代码",
          confirm_password: "确认密码",
          reset_password: "重置密码",
          edit_user_text: "编辑用户",
          add_user_text: "添加用户",
          asc: "升序",
          desc: "降序",
          credit: "信用",
          debit: "借记",
          direction: "方向",
          Status: "地位",
          document: "文件",
          document_placeholder_user: "输入用户的CPF",
          total_messages: "总消息",
          created_at: "创建日期",
          company_name: "公司名称",
          error_saved: "保存错误",
          success_saved: "保存成功",
          add_credits: "添加积分",
          amount: "数量",
          description: "描述",
          error_campaign_list: "待处理列表",
          alert_confirm_text_contact_error: "保存联系人错误",
          date_in: "入學日期",
          date_out: "截止日期",
          file_ready_to_upload: "文件準備上傳",
          uploaded_file: "文件已發送",
          transactions: "交易",
          balance: "平衡",
          Financial: "金融的",
          success_contact: "聯絡人保存成功",
          error_contact: "儲存聯絡人時發生錯誤",
          error_contact_list: "保存联系人列表错误",
          characters: "人物",
          alert_confirm_text_contact_list: "联系人列表已成功删除！",
          alert_confirm_text_contact_list_error: "删除联系人列表错误",
          additional_document_label: "附加文件",
          error_company: "保存公司错误",
          additional_document_placeholder: "输入 CPF",
          service: "服务",
          balancePos: "後付餘額",
          balancePre: "預付餘額",
          balancePostText: "後期餘額的值始終為零或負數。該餘額將在下一期間收取。",
          balancePreText: "預付餘額始終為零或正值。該餘額將用於發送訊息。",
          success_send_campaign: "活动发送成功！",
          alert_confirm_text_campaing_error: "删除错误",
          initial_date: "初始日期",
          final_date: "最終日期",
          initial_date_placeholder: "輸入初始日期",
          final_date_placeholder: "輸入最終日期",
          filter: "篩選",
          details: "細節",
          success_campaign: "活动保存成功！",
          error_campaign: "保存活动错误",
          error_campaign_get: "获取活动错误",
          success_service: "服务保存成功！",
          alert_confirm_text_service_error: "删除服务错误",
          error_service: "保存服务错误",
          delivery_fee: "配送费",
          batchdays_between_batchesmessages: "批次之间的天数",
          phone_number: "電話號碼",
          submit_date: "發送日期",
          done_date: "閒暇數據",
          gateway_message_id: "外部標識符",
          message: "門薩赫姆",
          save: "節省",
          days_between_batches: "批次之间的天数",
          gradual_increase: "逐步增加 %",
          batch_messages: "每批消息数",
          send_in_batches: "分批发送",
          now: "现在",
          later: "稍后",
          success_contact_list: "联系人列表保存成功！",
          success_gateway: "网关保存成功！",
          error_gateway: "保存网关错误",
          alert_confirm_title_error: "删除错误",
          alert_confirm_text_gateway_error: "删除网关错误",
          required_field: "必填字段",
          others: "其他",
          payload: "负载",
          "non-concatenated": "不连接",
          all: "全部",
          "non-ported": "非移植",
          ported: "已移植",
          loading: "加载中...",
          success_company: "公司添加成功！",
          reseller: "经销商",
          your_message_placeholder: "在此输入您的消息",
          your_message: "您的消息",
          model: "模型",
          personalized: "个性化",
          add_files: "添加文件",
          drag_in_drop_text: "拖动文件或点击浏览",
          send_to: "发送至",
          saved_list: "已保存列表",
          new_list: "新列表",
          block_label: "不要发送给（黑名单）",
          skip_label: "跳过重复联系人",
          numbers_placeholder:
            "输入以逗号分隔的号码。例：31912345678, 21912345678",
          numbers_label: "发送号码",
          sender_placeholder: "输入发件人名称",
          sender: "发件人",
          simple: "简单",
          list: "列表",
          select: "选择",
          info_text_list: "列表类型允许从系统中选择联系人列表或上传新文件。",
          info_text_simple: "简单类型允许输入一个或多个以逗号分隔的发送号码。",
          reports: "报告",
          campaign: "活动",
          campaigns: "活动",
          Campaign: "活動",
          Delivered: "已交付",
          Undelivered: "未交付",
          Scheduled: "預定",
          Rejected: "被拒絕",
          Accepted: "公認",
          Unknown: "未知",
          EnRoute: "等待接線員",
          InvalidNumber: "號碼無效",
          Processing: "加工",
          Waiting: "已發送",
          Expired: "已到期",
          reports_detailed: "詳細報告",
          group_by_campaign: "按活動分組",
          name_label_campaign: "活动名称",
          name_placeholder_campaign: "输入活动名称",
          alert_confirm_text_campaing: "活动已删除！",
          add_campaign: "添加活动",
          edit_campaign: "编辑活动",
          schedule: "时间表",
          recipients: "收件人",
          handed_out: "已送达",
          example_file: "示例文件",
          download_file: "下载 CSV 文件",
          credits: "积分",
          charge_mo: "按处理的 MO 收费",
          fail_over: "故障转移",
          operators: "运营商",
          concatenated_send: "连接发送",
          rule_for_concatenates: "连接规则",
          rule_for_portability: "移植规则",
          speed: "速度",
          value: "值",
          own_cost: "自费",
          password_gateway: "密码",
          user_gateway: "用户",
          port: "端口",
          identifier: "标识符",
          add_gateway: "+ 添加网关",
          add_gateway_text: "添加网关",
          shipping_type: "运输类型",
          server: "服务器",
          alert_confirm_text_gateway: "网关已删除！",
          alert_confirm_text_service: "服务已删除！",
          rn1: "使用 RN1 计费",
          concatenated: "已连接",
          sms_service: "短信服务",
          block_free: "阻止免费发送",
          alert_prepaid: "请选择是否为预付费！",
          prepaid: "预付费",
          add_service: "+ 添加服务",
          add_service_text: "添加服务",
          gateway: "网关",
          alert_confirm_text_contactlist: "联系人列表已删除！",
          alert_confirm_text_blacklist: "黑名单已删除！",
          submit: "提交",
          var_off: "隐藏变量",
          duplicity_label: "移除重复",
          import_contact_label: "联系人文件",
          import_contact_title: "导入联系人",
          address: "地址",
          add_contact_title: "添加联系人",
          edit_contact_title: "编辑联系人",
          number_label: "号码",
          add_blacklist: "+ 添加黑名单",
          edit_blacklist_title: "编辑黑名单",
          add_blacklist_title: "添加黑名单",
          alert_confirm_text_contact: "联系人已删除",
          add_contact: "添加联系人",
          import_contact: "导入联系人",
          edit_contactlist_title: "编辑联系人列表",
          numbers_procon: "在 procon 中阻止的号码",
          number: "号码",
          state: "状态",
          creation_date: "创建日期",
          access: "访问",
          quantity: "数量",
          company_alert: "请选择一家公司。",
          tools: "工具",
          contactlist: "联系人列表",
          blacklist: "黑名单",
          procon: "Procon",
          contacts: "联系方式",
          user_text: "搜索、过滤、添加、编辑或删除您的用户",
          add_user: "+ 添加用户",
          manage_users: "管理用户",
          clients: "客户",
          user_name_label_user: "用户名",
          user_name_placeholder_user: "输入你的用户名",
          password_label_user: "密码",
          password_placeholder_user: "输入你的密码",
          email_label_user: "电子邮件",
          email_placeholder_user: "输入你的电子邮件",
          phone_label_user: "电话",
          phone_placeholder_user: "输入你的电话号码",
          first_name_label_user: "名字",
          first_name_placeholder_user: "输入你的名字",
          last_name_label_user: "姓氏",
          last_name_placeholder_user: "输入你的姓氏",
          type_label_user: "类型",
          active_label_user: "活跃",
          user_name_alert_user: "请输入有效的用户名",
          password_alert_user: "请输入有效的密码",
          active_alert_user: "请指定是否激活",
          email_alert_user: "请输入有效的电子邮件",
          phone_alert_user: "请输入有效的电话号码",
          first_name_alert_user: "请输入有效的名字",
          last_name_alert_user: "请输入有效的姓氏",
          type_alert_user: "请输入有效的类型",
          username: "用户名",
          email: "电子邮件",
          company: "公司",
          type: "类型",
          active: "活跃",
          actions: "操作",
          resale: "转售",
          client: "客户",
          branch: "分支",
          inactive: "未激活",
          cnpj_alert: "请输入有效的CNPJ",
          name_alert: "请输入有效的名字",
          logo_alert: "请上传有效的标志",
          logo_reduced_alert: "请上传有效的缩略标志",
          type_alert: "请提供有效的类型",
          active_alert: "请指定是否激活",
          corporate_reason_alert: "请输入有效的公司原因",
          processing: "处理中",
          paused: "暫停",
          pending: "待辦的",
          sent: "完成",
          state_registration_alert: "请输入有效的州注册",
          address_alert: "请提供有效的地址",
          financial_phone_alert: "请提供有效的财务电话号码",
          technical_phone_alert: "请提供有效的技术电话号码",
          financial_email_alert: "请提供有效的财务电子邮件",
          technical_email_alert: "请提供有效的技术电子邮件",
          observations_alert: "请输入您的意见",
          name_label: "名字",
          cnpj_label: "CNPJ",
          logo_label: "标志 (480x140)",
          logo_reduced_label: "缩略标志 (100x100)",
          corporate_reason_label: "公司原因",
          state_registration_label: "州注册",
          type_label: "类型",
          address_label: "地址",
          financial_phone_label: "财务电话号码",
          technical_phone_label: "技术电话号码",
          financial_email_label: "财务电子邮件",
          technical_email_label: "技术电子邮件",
          observations_label: "意见",
          active_label: "激活",
          name_placeholder: "请输入您的名字",
          cnpj_placeholder: "请输入您的CNPJ",
          corporate_reason_placeholder: "请输入公司原因",
          state_registration_placeholder: "请输入州注册",
          address_placeholder: "请输入地址",
          phone_placeholder: "輸入電話。",
          financial_phone_placeholder: "请输入财务电话号码",
          technical_phone_placeholder: "请输入技术电话号码",
          financial_email_placeholder: "请输入财务电子邮件",
          technical_email_placeholder: "请输入技术电子邮件",
          observations_placeholder: "请输入您的意见",
          account: "帐户",
          settings: "设置",
          logout: "登出",
          alert_title: "你确定吗？",
          alert_text: "您将无法恢复此操作！",
          alert_button: "是的，删除！",
          alert_cancel: "取消",
          alert_confirm_title: "已删除",
          alert_confirm_text: "该公司已被删除。",
          alert_confirm_text_user: "该用户已被删除！",
          add_company: "+ 添加公司",
          add_company_text: "添加公司",
          balance_text: "公司的平衡、投入、產出",
          company_identifier: "公司識別符",
          inputs: "輸入",
          outputs: "輸出",
          Company: "公司",
          success_transaction: "交易保存成功",
          add_transaction: "新增交易",
          add_transaction_text: "新增交易",
          edit: "编辑",
          delete: "删除",
          transactions_text: "搜尋、過濾、新增、編輯或刪除交易",
          company_text: "搜索、过滤、添加、编辑或删除您的客户",
          name: "姓名",
          top_company: "顶级公司",
          search: "搜索",
          error_login: "无效的电子邮件或密码！",
          sig_in_text: "登录账户",
          enter_email: "输入您的电子邮件和密码登录",
          password: "密码",
          sig_in: "登入",
          forgot_password: "忘记密码",
          email_addres: "电子邮件地址",
          send: "发送",
          sms: "短信",
          Configurations: "设置",
          Campaigns: "活动",
          "Sending Single": "单位运输",
          "Sending by File": "通过文件发送",
          "Sending by Contact List": "通过联系人列表发送",
          Messages: "留言",
          Companies: "公司",
          companies_add: "公司地址",
          Services: "服务",
          General: "通用",
          Dashboards: "仪表板",
          Default: "默认",
          Project: "项目",
          Ecommerce: "电子商务",
          Education: "教育",
          Widgets: "小部件",
          Chart: "图表",
          Applications: "应用程序",
          "Project List": "项目列表",
          "Create New": "创建新",
          "File Manager": "文件管理器",
          "Add Product": "添加产品",
          Product: "产品",
          "Product Page": "产品页面",
          "Product List": "产品列表",
          "Payment Details": "付款详情",
          "Order History": "订单历史",
          Invoice: "发票",
          "Invoice-1": "发票-1",
          "Invoice-2": "发票-2",
          "Invoice-3": "发票-3",
          "Invoice-4": "发票-4",
          "Invoice-5": "发票-5",
          "Invoice-6": "发票-6",
          Cart: "购物车",
          Wishlist: "愿望清单",
          Checkout: "结账",
          Pricing: "定价",
          Chat: "聊天",
          Users: "用户",
          "Letter Box": "邮箱",
          "Private Chat": "私聊",
          "Group Chat": "群聊",
          "User Profile": "用户资料",
          "User Edit": "编辑用户",
          "User Cards": "用户卡片",
          Bookmarks: "书签",
          Contacts: "联系人",
          Task: "任务",
          Calendar: "日历",
          "Social App": "社交应用",
          Todo: "待办事项",
          "Search Result": "搜索结果",
          "Form Validation": "表单验证",
          "Base Inputs": "基本输入",
          "Checkbox & Radio": "复选框和单选按钮",
          "Input Groups": "输入组",
          "Input Mask": "输入掩码",
          "Mega Option": "超级选项",
          Datepicker: "日期选择器",
          Touchspin: "Touchspin",
          Switch: "开关",
          Forms: "表单",
          "Form Controls": "表单控件",
          "Forms & Table": "表单和表格",
          Typeahead: "类型提示",
          Clipboard: "剪贴板",
          "Form Widget": "表单小部件",
          "Form Wizard 1": "表单向导1",
          "Form Wizard 2": "表单向导2",
          "Two Factor": "双因素认证",
          "Form Layout": "表单布局",
          Table: "表格",
          "Reactstrap Tables": "Reactstrap表格",
          "Basic Tables": "基本表格",
          "Table Components": "表格组件",
          "Data Tables": "数据表",
          "Basic Init": "基本初始化",
          "Advance Init": "高级初始化",
          API: "API",
          "Data Source": "数据源",
          Components: "组件",
          Typography: "排版",
          Avatars: "头像",
          "Helper Classes": "辅助类",
          Grid: "网格",
          "Tag & Pills": "标签和药丸",
          Progress: "进度",
          Modal: "模态框",
          Alert: "警告",
          Popover: "弹出框",
          Tooltip: "工具提示",
          Dropdown: "下拉菜单",
          Accordion: "手风琴",
          Tabs: "选项卡",
          Lists: "列表",
          "Bonus-Ui": "附加界面",
          Scrollable: "可滚动",
          "Tree View": "树视图",
          Toasts: "消息提醒",
          Rating: "评分",
          Dropzone: "文件上传",
          Tour: "新手导览",
          SweetAlert2: "SweetAlert2",
          "Owl Carousel": "Owl 走马灯",
          Ribbons: "带子",
          Pagination: "分页",
          Breadcrumb: "面包屑",
          "Range Slider": "范围滑块",
          "Image Cropper": "图片裁剪",
          "Basic Card": "基本卡片",
          "Creative Card": "创意卡片",
          Timeline: "时间线",
          Icons: "图标",
          "Flag Icon": "国旗图标",
          "Fontawesome Icon": "Font Awesome 图标",
          "Ico Icon": "Ico 图标",
          "Themify Icon": "Themify 图标",
          "Feather Icon": "Feather 图标",
          "Weather Icon": "天气图标",
          Buttons: "按钮",
          "Default Style": "默认样式",
          "Flat Style": "扁平样式",
          "Edge Style": "边框样式",
          "Raised Style": "浮凸样式",
          "Button Group": "按钮组",
          Charts: "图表",
          "Apex Chart": "Apex 图表",
          "Google Chart": "谷歌图表",
          "Chart JS Chart": "Chart JS 图表",
          Pages: "页面",
          "Sample Page": "示例页面",
          Others: "其他",
          "Error Pages": "错误页面",
          "Error 400": "错误 400",
          "Error 401": "错误 401",
          "Error 403": "错误 403",
          "Error 404": "错误 404",
          "Error 500": "错误 500",
          "Error 503": "错误 503",
          "Login Simple": "简单登录",
          "Login with Bg Image": "带背景图片的登录",
          "Login With Image Two": "带两张图片的登录",
          "Login With Validation": "带验证的登录",
          "Login With Tooltip": "带提示的登录",
          "Login With Sweetalert": "带 SweetAlert 的登录",
          "Register Simple": "简单注册",
          "Register With Bg Image": "带背景图片的注册",
          "Register With Bg Two": "带背景图片的注册",
          "Register Wizard": "注册向导",
          "Unlock User": "解锁用户",
          "Forget Password": "忘记密码",
          "Reset Password": "重设密码",
          Maintenance: "维护",
          "Coming Simple": "即将上线",
          "Coming With Bg Video": "带背景视频的即将上线",
          "Coming With Bg Image": "带背景图片的即将上线",
          Miscellaneous: "其他",
          Gallery: "图库",
          "Gallery Grids": "图库网格",
          "Gallery Grid Desc": "图库网格描述",
          "Masonry Gallery": "砖瓦图库",
          "Masonry With Desc": "砖瓦图库描述",
          "Hover Effect": "悬停效果",
          Blog: "博客",
          "Blog Details": "博客详情",
          "Blog Single": "单篇博客",
          "Add Post": "添加文章",
          FAQ: "常见问题",
          "Job Search": "职位搜索",
          "Cards View": "卡片视图",
          "List View": "列表视图",
          "Job Detail": "职位详情",
          Apply: "申请",
          Learning: "学习",
          "Learning List": "学习列表",
          "Detailed Course": "详细课程",
          Map: "地图",
          "Google Map": "谷歌地图",
          "Leaflet Map": "Leaflet 地图",
          Editor: "编辑器",
          "CK Editor": "CK 编辑器",
          "MDE Editor": "MDE 编辑器",
          "ACE Editor": "ACE 编辑器",
          Knowledgebase: "知识库",
          "Ui-Kits": "界面套件",
          "Support Ticket": "支持工单",
          Authentication: "身份验证",
          "Login With Bg Image": "带背景图像的登录",
          Pinned: "已固定",
          "Coming Soon": "即将上线",
        },
      },
      pt: {
        translations: {
          queue_server_problem: "Linha desabilitada ou com problemas. Entre em contato com o suporte.",
          no_contacts_found: "Lista de contatos não encontrada",
          click_to_upload_file: "Clique aqui para selecionar um arquivo para Upload.",
          upload_file_accepted_format: "Formatos aceitos: .txt, .csv (máx. 3MB)",
          selected_upload_file: "Arquivo selecionado: ",
          gateway_not_found: "Linha desabilitada ou com problemas. Entre em contato com o suporte.",
          invalid_campaign_state: "Erro geral ao processar campanha",
          error_processing_campaign: "Erro geral ao processar campanha",
          message_not_configured_in_campaign: "Message not configured in campaign",
          service_not_configured_in_campaign: "Linha desabilitada ou com problemas. Entre em contato com o suporte.",
          company_not_found: "Erro geral ao processar campanha",
          service_not_found: "Linha desabilitada ou com problemas. Entre em contato com o suporte.",
          service_inactive:"Linha desabilitada ou com problemas. Entre em contato com o suporte.",
          cost_not_configured_to_campaign: "Linha desabilitada ou com problemas. Entre em contato com o suporte.",
          gateway_and_service_not_found: "Linha desabilitada ou com problemas. Entre em contato com o suporte.",
          gateway_inactive: "Linha desabilitada ou com problemas. Entre em contato com o suporte.",
          no_gateway_code_configured: "Linha desabilitada ou com problemas. Entre em contato com o suporte.",
          gateway_not_found_for_service: "Linha desabilitada ou com problemas. Entre em contato com o suporte.",
          failed_to_create_queue_batch: "Erro geral ao processar campanha",
          queue_batch_not_found: "Erro geral ao processar campanha",
          failed_to_retrieve_balance: "Erro geral ao processar campanha",
          insufficient_balance: "Saldo Insuficiente",
          insufficient_balance_for_campaign_sending: "Saldo Insuficiente",
          campaign_processing_error: "Erro geral ao processar campanha",
          body_not_informed: "Erro geral ao processar campanha",
          schedule_date_or_time_or_timezone_not_informed: "Dados para agendamento de campanha não informados",
          invalid_contact_file: "Lista de contatos inválida",
          invalid_contact_file_extension: "Extensão de arquivo de lista de contatos inválida",
          queue_not_found: "Linha desabilitada ou com problemas. Entre em contato com o suporte.",
          no_data: "Sem dados",
          not_delivered_report_numbers: "Relatório de não entregues (Números)",
          campaign_message_report: "Relatório de mensagens de campanha",
          download_campaign_reports: "Baixar relatórios de campanha",
          write_email:
            "Digite seu e-mail para que possamos lhe enviar o código para alteração",
          
          preparing: "Em Preparação",
          forbidden: "Proibido",
          sent_operator: "Enviado Operadora",
          limit_contacts_per_campaign: "Limite de contatos por campanha: 50.000 contatos (50k)",
          monthly_messages_sent: "Mensagens enviadas no mês",
          year_monthly_messages: "Anual: Mensagens enviadas",
          sms_monthly_spend: "Gastos mensal com SMS",
          daily_cost: "Custo Diário",
          monthly_cost: "Custo Mensal",
          daily_delivered_not_delivered: "Hoje: Entregue x Não Entregue",
          daily_sms_status: "Hoje: Status de Entrega",
          daily_sms_total_other_states: "Hoje: Total SMS outros status",
          daily_sms_total_delivered: "Hoje: Total SMS entregue",
          daily_sms_total_sent: "Hoje: Total SMS enviado",
          year_monthly_messages_cost: "Anual: Custo de mensagens",
          yearly_cost: "Custo Anual",
          view: "Visualizar",
          duplicate: "Duplicar",
          view_service: "Visualizar serviço",
          schedule_date_placeholder: "Digite a data de agendamento",
          schedule_time_placeholder: "Digite o horário de agendamento",
          schedule_date: "Data de Agendamento",
          schedule_time: "Horario de Agendamento (HH:MM)",
          NotSent: "Não enviado",
          scheduled: "Agendada",
          recipient: "Contatos",
          success_schedule_campaign: "Campanha agendada com sucesso!",
          error_loading_user_data: "Erro ao carregar dados do usuário",
          alert_confirm_text_gateway_error_active: "O Gateway está ativo",
          error_user_already_exists: "Usuário já existe",
          debit_by_use: "Débito por uso",
          error: "Erro",
          sms_type: "Tipo de SMS",
          sms_type_text: "O formato de disparo para seu SMS",
          campaign_service_text: "O serviço que sua campanha usará.",
          recipient_text: "O contato o qual se destina o seu SMS.",
          scheduling: "Agendamento",
          scheduling_text: "Caso queira agendar o envio da sua campanha.",
          message_content: "Mensagem",
          message_content_text: "Criar e visualizar o conteúdo de seu SMS.",
          owner: "Proprietário",
          credit_text: "Listagem de Créditos e Débitos",
          subtype: "Subtipo",
          credit_transfer: "Transferência",
          add_credit_text: "Adicionar créditos",
          deposit: "Depósito",
          select_period: "Selecione o período",
          pos_paid_text: "Listagem de Pós pagos",
          period: "Período",
          status: "Status",
          close_date: "Data de fechamento",
          delayed_payment_days: "Dias de atraso",
          pos_paid: "Pós pago",
          use_api: "Utilizar API",
          service_to_api: "Serviço para API",
          report: "Relatório",
          open: "Aberto",
          paid: "Pago",
          yes: "Sim",
          no: "Não",
          Jan: "Jan", 
          Feb: "Fev", 
          Mar: "Mar", 
          Apr: "Abr", 
          May: "Mai", 
          Jun: "Jun", 
          Jul: "Jul", 
          Aug: "Ago", 
          Sep: "Set", 
          Oct: "Out", 
          Nov: "Nov", 
          Dec: "Dez",
          total: "Total",
          cancel: "Cancelar",
          login1: "Código enviado para o e-mail",
          login1_error: "Erro ao enviar código para o e-mail",
          login2: "Senha redefinida com sucesso",
          login2_error: "Erro ao redefinir senha",
          code: "Código",
          confirm_password: "Confirmar senha",
          reset_password: "Resetar senha",
          edit_user_text: "Editar usuário",
          add_user_text: "Adicionar usuário",
          asc: "Crescente",
          desc: "Decrescente",
          credit: "Crédito",
          debit: "Débito",
          direction: "Direção",
          Status: "Status",
          document: "Documento",
          document_placeholder_user: "Digite o CPF do usuário",
          total_messages: "Total de mensagens",
          created_at: "Data de criação",
          company_name: "Nome da empresa",
          error_saved: "Erro ao salvar",
          success_saved: "Salvo com sucesso",
          add_credits: "Adicionar créditos",
          amount: "Quantidade",
          description: "Descrição",
          error_campaign_list: "Lista pendente",
          alert_confirm_text_contact_error: "Erro ao salvar contato",
          date_in: "Data de entrada",
          date_out: "Data de validade",
          uploaded_file: "Arquivo enviado",
          file_ready_to_upload: "Arquivo pronto para subir.",
          transactions: "Transações",
          balance: "Saldo",
          Financial: "Financeiro",
          success_contact: "Contato salvo com sucesso",
          error_contact_list: "Erro ao salvar lista de contatos",
          characters: "caracteres",
          error_contact: "Erro ao salvar contato",
          alert_confirm_text_contact_list:
            "Lista de contatos excluída com sucesso!",
          alert_confirm_text_contact_list_error:
            "Erro ao excluir lista de contatos",
          additional_document_label: "Documento adicional",
          error_company: "Erro ao salvar empresa",
          additional_document_placeholder: "Digite o CPF",
          service: "Serviço",
          balancePos: "Saldo (Pós Pago)",
          balancePre: "Saldo (Pré Pago)",
          balancePosText: "O saldo pós pago sempre estará com o valor em zero ou negativo. Este saldo será cobrado no próximo período.",
          balancePreText: "O saldo pré pago sempre estará com valor em zero ou positivo. Este saldo será utilizado para envio de mensagens.",
          success_send_campaign: "Campanha enviada com sucesso!",
          alert_confirm_text_campaing_error: "Erro ao excluir",
          filter: "Filtrar",
          details: "Detalhes",
          initial_date: "Data inicial",
          final_date: "Data final",
          initial_date_placeholder: "Digite a data inicial",
          final_date_placeholder: "Digite a data final",
          success_campaign: "Campanha salva com sucesso!",
          error_campaign: "Erro ao salvar campanha",
          error_campaign_get: "Erro ao buscar campanha",
          success_service: "Serviço salvo com sucesso!",
          alert_confirm_text_service_error: "Erro ao excluir serviço",
          error_service: "Erro ao salvar serviço",
          delivery_fee: "Taxa de entrega",
          batchdays_between_batchesmessages: "Dias entre lotes",
          phone_number: "Telefone",
          submit_date: "Data de envio",
          done_date: "Data de leitura",
          gateway_message_id: "Identificador externo",
          message: "Mensagem",
          save: "Salvar",
          days_between_batches: "Dias entre lotes",
          gradual_increase: "Aumento gradual %",
          batch_messages: "Mensagens por lote",
          send_in_batches: "Enviar em lotes",
          now: "Agora",
          later: "Mais tarde",
          success_contact_list: "Lista de contatos salva com sucesso!",
          success_gateway: "Gateway salvo com sucesso!",
          error_gateway: "Erro ao salvar gateway",
          alert_confirm_title_error: "Erro ao excluir",
          alert_confirm_text_gateway_error: "Erro ao excluir gateway",
          required_field: "Campo obrigatório",
          others: "Outras",
          payload: "Payload",
          "non-concatenated": "Não concatenados",
          all: "Todos",
          "non-ported": "Não portados",
          ported: "Portados",
          loading: "Carregando...",
          success_company: "Empresa adicionada com sucesso!",
          reseller: "Revendedor",
          your_message_placeholder: "Digite sua mensagem aqui",
          your_message: "Sua mensagem",
          model: "Modelo",
          personalized: "Personalizada",
          add_files: "Adicionar arquivos",
          drag_in_drop_text: "Arraste os arquivos ou clique para procurar",
          send_to: "Enviar para",
          saved_list: "Lista salva",
          new_list: "Nova lista",
          block_label: "Não enviar para (blacklist)",
          skip_label: "Pular contatos duplicados",
          numbers_placeholder:
            "Insira números separados por vírgula. Ex: 31912345678, 21912345678",
          numbers_label: "Números para disparo",
          sender_placeholder: "Insira o nome do remetente",
          sender: "Remetente",
          simple: "Simples",
          list: "Lista",
          select: "Selecione",
          info_text_list:
            "O tipo Lista permite selecionar uma lista de contatos do sistema ou subir um arquivo novo.",
          info_text_simple:
            "O tipo Simples permite inserir um ou mais números para disparo separados por vírgula.",
          reports: "Relatórios",
          campaign: "Campanha",
          campaigns: "Campanhas",
          Campaign: "Campanha",
          Delivered: "Entregue",
          Undelivered: "Não Entregue",
          Scheduled: "Agendado",
          Rejected: "Rejeitado",
          Accepted: "Aceito",
          EnRoute: "Aguardando Operadora",
          Unknown: "Desconhecido",
          InvalidNumber: "Número Inválido",
          Processing: "Processando",
          Waiting: "Enviado",
          Expired: "Expirado",
          reports_detailed: "Relatório Detalhado",
          group_by_campaign: "Agrupar por Campanha",
          name_label_campaign: "Nome da campanha",
          name_placeholder_campaign: "Digite o nome da campanha",
          alert_confirm_text_campaing: "A campanha foi excluída!",
          add_campaign: "Adicionar campanha",
          edit_campaign: "Editar campanha",
          schedule: "Programação",
          recipients: "Destinatários",
          handed_out: "Entregues",
          example_file: "Arquivo de exemplo",
          download_file: "Baixar arquivo CSV",
          credits: "Créditos",
          charge_mo: "Cobrar por MO processado",
          fail_over: "Fail Over",
          operators: "Operadora",
          concatenated_send: "Envio concatenado",
          rule_for_concatenates: "Regra para concatenados",
          rule_for_portability: "Regra para portabilidade",
          speed: "Velocidade",
          value: "Valor",
          own_cost: "Custo próprio",
          password_gateway: "Senha",
          user_gateway: "Usuário",
          port: "Porta",
          identifier: "Identificador",
          add_gateway: "+ Adicionar gateway",
          add_gateway_text: "Adicionar gateway",
          shipping_type: "Tipo de envio",
          server: "Servidor",
          alert_confirm_text_gateway: "O gateway foi excluído!",
          alert_confirm_text_service: "O serviço foi excluído!",
          rn1: "Use RN1 para faturamento",
          concatenated: "Concatenado",
          sms_service: "Serviço de SMS",
          block_free: "Bloquear remessas gratuitas",
          alert_prepaid: "Por favor, selecione se é pré pago!",
          prepaid: "Pré pago",
          add_service: "+ Adicionar serviço",
          add_service_text: "Adicionar serviço",
          gateway: "Gateway",
          alert_confirm_text_contactlist: "A Lista de contatos foi excluída!",
          alert_confirm_text_blacklist: "A Lista negra foi excluída!",
          submit: "Enviar",
          var_off: "Esconder variáveis",
          duplicity_label: "Remover duplicidade",
          import_contact_label: "Arquivo com o contato",
          import_contact_title: "Importar contato",
          address: "Endereço",
          add_contact_title: "Adicionar contato",
          edit_contact_title: "Editar contato",
          number_label: "Número",
          add_blacklist: "+ Adicionar lista negra",
          edit_blacklist_title: "Editar lista negra",
          add_blacklist_title: "Adicionar lista negra",
          alert_confirm_text_contact: "O contato foi excluído",
          add_contact: "Adicionar contato",
          import_contact: "Importar contato",
          edit_contactlist_title: "Editar lista de contatos",
          numbers_procon: "Números bloqueados no procon",
          number: "Número",
          state: "Estado",
          creation_date: "Data de criação",
          access: "Acessar",
          quantity: "Quantidade",
          company_alert: "Por favor, selecione uma empresa.",
          tools: "Ferramentas",
          contactlist: "Lista de contatos",
          add_contactlist: "+ Adicionar Lista de contatos",
          add_contactlist_title: "Adicionar Lista de contatos",
          blacklist: "Lista negra",
          procon: "Procon",
          contacts: "Contatos",
          user_text:
            "Pesquise, filtre, adicione, edite ou exclua seus usuários",
          add_user: "+ Adicionar usuário",
          manage_users: "Gerenciar usuários",
          clients: "Clientes",
          user_name_label_user: "Nome de Usuário",
          user_name_placeholder_user: "Insira seu nome de usuário",
          password_label_user: "Senha",
          password_placeholder_user: "Insira sua senha",
          email_label_user: "Email",
          email_placeholder_user: "Insira seu email",
          phone_label_user: "Telefone",
          phone_placeholder_user: "Insira seu número de telefone",
          first_name_label_user: "Nome",
          first_name_placeholder_user: "Insira seu nome",
          last_name_label_user: "Sobrenome",
          last_name_placeholder_user: "Insira seu sobrenome",
          type_label_user: "Tipo",
          active_label_user: "Ativo",
          user_name_alert_user: "Por favor, insira um nome de usuário válido",
          password_alert_user: "Por favor, insira uma senha válida",
          active_alert_user: "Por favor, especifique se está ativo",
          email_alert_user: "Por favor, insira um email válido",
          phone_alert_user: "Por favor, forneça um número de telefone válido",
          first_name_alert_user: "Por favor, insira um nome válido",
          last_name_alert_user: "Por favor, insira um sobrenome válido",
          type_alert_user: "Por favor, insira um tipo válido",
          username: "Nome de usuário",
          email: "Email",
          company: "Empresa",
          type: "Tipo",
          active: "Ativa",
          actions: "Ações",
          resale: "Revenda",
          client: "Cliente",
          branch: "Filial",
          inactive: "Inativo",
          cnpj_alert: "Por favor, insira um CNPJ válido",
          name_alert: "Por favor, insira um nome válido",
          logo_alert: "Por favor, carregue um logotipo válido",
          logo_reduced_alert: "Por favor, carregue um logotipo reduzido válido",
          type_alert: "Por favor, forneça um tipo válido",
          active_alert: "Por favor, especifique se está ativo",
          corporate_reason_alert: "Por favor, insira um motivo social válido",
          processing: "Em Processamento",
          paused: "Pausado",
          pending: "Pendente",
          sent: "Concluída",
          state_registration_alert:
            "Por favor, insira uma inscrição estadual válida",
          address_alert: "Por favor, forneça um endereço válido",
          financial_phone_alert:
            "Por favor, forneça um número de telefone financeiro válido",
          technical_phone_alert:
            "Por favor, forneça um número de telefone técnico válido",
          financial_email_alert:
            "Por favor, forneça um e-mail financeiro válido",
          technical_email_alert: "Por favor, forneça um e-mail técnico válido",
          observations_alert: "Por favor, insira suas observações",
          name_label: "Nome",
          cnpj_label: "CNPJ",
          logo_label: "Logotipo (480x140)",
          logo_reduced_label: "Logotipo Reduzido (100x100)",
          corporate_reason_label: "Motivo Social",
          state_registration_label: "Inscrição Estadual",
          type_label: "Tipo",
          address_label: "Endereço",
          financial_phone_label: "Telefone Financeiro",
          technical_phone_label: "Telefone Técnico",
          financial_email_label: "E-mail Financeiro",
          technical_email_label: "E-mail Técnico",
          observations_label: "Observações",
          active_label: "Ativo",
          name_placeholder: "Digite seu nome",
          cnpj_placeholder: "Digite seu CNPJ",
          corporate_reason_placeholder: "Digite o motivo social",
          state_registration_placeholder: "Digite a inscrição estadual",
          address_placeholder: "Digite o endereço",
          phone_placeholder: "Digite o telefone",
          financial_phone_placeholder: "Digite o telefone financeiro",
          technical_phone_placeholder: "Digite o telefone técnico",
          financial_email_placeholder: "Digite o e-mail financeiro",
          technical_email_placeholder: "Digite o e-mail técnico",
          observations_placeholder: "Digite suas observações",
          account: "Conta",
          settings: "Configurações",
          logout: "Sair",
          alert_title: "Tem certeza?",
          alert_text: "Você não poderá reverter isso!",
          alert_button: "Sim, excluir!",
          alert_cancel: "Cancelar",
          alert_confirm_title: "Excluído",
          alert_confirm_text: "A empresa foi excluída!",
          alert_confirm_text_user: "O usuário foi excluído!",
          add_company: "+ Adicionar empresa",
          add_company_text: "Adicionar empresa",
          balance_text: "Saldo, entradas e saídas da empresa",
          inputs: "Entradas",
          outputs: "Saídas",
          company_identifier: "Empresa",
          Company: "Empresa",
          success_transaction: "Transação salva com sucesso!",
          edit: "Editar",
          delete: "Excluir",
          add_transaction: "+ Adicionar transação",
          add_transaction_text: "Adicionar transação",
          transactions_text: "Pesquise, filtre, adicione, edite ou exclua transações",
          company_text: "Pesquise, filtre, adicione, edite ou exclua seus clientes",
          name: "Nome",
          top_company: "Empresa superior",
          search: "Buscar",
          error_login: "Email ou senha inválidos!",
          sig_in_text: "Faça login na conta",
          enter_email: "Digite seu e-mail e senha para fazer login",
          email_addres: "Endereço de Email",
          password: "Senha",
          sig_in: "Entrar",
          forgot_password: "Esqueceu sua senha",
          send: "Enviar",
          sms: "SMS",
          Configurations: "Configurações",
          Campaigns: "Campanhas",
          "Sending Single": "Envio unitário",
          "Sending by File": "Envio por arquivo",
          "Sending by Contact List": "Envio por lista de contatos",
          Messages: "Mensagens",
          Companies: "Empresas",
          companies_add: "Adicionar Empresa",
          Services: "Serviços",
          General: "Geral",
          Dashboards: "Painéis",
          Default: "Padrão",
          Project: "Projeto",
          Ecommerce: "Comércio Eletrônico",
          Education: "Educação",
          Widgets: "Widgets",
          Chart: "Gráfico",
          Applications: "Aplicativos",
          "Project List": "Lista de Projetos",
          "Create New": "Criar Novo",
          "File Manager": "Gerenciador de Arquivos",
          "Add Product": "Adicionar Produto",
          Product: "Produto",
          "Product Page": "Página do Produto",
          "Product List": "Lista de Produtos",
          "Payment Details": "Detalhes do Pagamento",
          "Order History": "Histórico de Pedidos",
          Invoice: "Fatura",
          "Invoice-1": "Fatura-1",
          "Invoice-2": "Fatura-2",
          "Invoice-3": "Fatura-3",
          "Invoice-4": "Fatura-4",
          "Invoice-5": "Fatura-5",
          "Invoice-6": "Fatura-6",
          Cart: "Carrinho",
          Wishlist: "Lista de Desejos",
          Checkout: "Finalizar Compra",
          Pricing: "Preços",
          Chat: "Chat",
          Users: "Usuários",
          "Letter Box": "Caixa de Correio",
          "Private Chat": "Chat Privado",
          "Group Chat": "Chat em Grupo",
          "User Profile": "Perfil do Usuário",
          "User Edit": "Editar Usuário",
          "User Cards": "Cartões de Usuário",
          Bookmarks: "Marcadores",
          Contacts: "Contatos",
          Task: "Tarefa",
          Calendar: "Calendário",
          "Social App": "Aplicativo Social",
          Todo: "Lista de Tarefas",
          "Search Result": "Resultado da Pesquisa",
          "Form Validation": "Validação de Formulário",
          "Base Inputs": "Entradas Básicas",
          "Checkbox & Radio": "Caixas de Seleção e Rádio",
          "Input Groups": "Grupos de Entrada",
          "Input Mask": "Máscara de Entrada",
          "Mega Option": "Opção Mega",
          Datepicker: "Seletor de Data",
          Touchspin: "Touchspin",
          Switch: "Interruptor",
          Forms: "Formulários",
          "Form Controls": "Controles de Formulário",
          "Forms & Table": "Formulários e Tabela",
          Typeahead: "Sugestão de Texto",
          Clipboard: "Área de Transferência",
          "Form Widget": "Widget de Formulário",
          "Form Wizard 1": "Assistente de Formulário 1",
          "Form Wizard 2": "Assistente de Formulário 2",
          "Two Factor": "Autenticação em Dois Passos",
          "Form Layout": "Layout do Formulário",
          Table: "Tabela",
          "Reactstrap Tables": "Tabelas Reactstrap",
          "Basic Tables": "Tabelas Básicas",
          "Table Components": "Componentes de Tabela",
          "Data Tables": "Tabelas de Dados",
          "Basic Init": "Inicialização Básica",
          "Advance Init": "Inicialização Avançada",
          API: "API",
          "Data Source": "Fonte de Dados",
          Components: "Componentes",
          Typography: "Tipografia",
          Avatars: "Avatares",
          "Helper Classes": "Classes de Ajuda",
          Grid: "Grade",
          "Tag & Pills": "Etiqueta e Abas",
          Progress: "Progresso",
          Modal: "Modal",
          Alert: "Alerta",
          Popover: "Popover",
          Tooltip: "Dica de Ferramenta",
          Dropdown: "Menu Suspenso",
          Accordion: "Acordeão",
          Tabs: "Abas",
          Lists: "Listas",
          "Bonus-Ui": "UI Extra",
          Scrollable: "Rolável",
          "Tree View": "Visualização de Árvore",
          Toasts: "Notificações",
          Rating: "Avaliação",
          Dropzone: "Zona de Soltar",
          Tour: "Tour",
          SweetAlert2: "SweetAlert2",
          "Owl Carousel": "Carrossel Owl",
          Ribbons: "Fitas",
          Pagination: "Paginação",
          Breadcrumb: "Migalha de Pão",
          "Range Slider": "Controle de Faixa",
          "Image Cropper": "Cortador de Imagem",
          "Basic Card": "Cartão Básico",
          "Creative Card": "Cartão Criativo",
          Timeline: "Linha do Tempo",
          Icons: "Ícones",
          "Flag Icon": "Ícone de Bandeira",
          "Fontawesome Icon": "Ícone Fontawesome",
          "Ico Icon": "Ícone Ico",
          "Themify Icon": "Ícone Themify",
          "Feather Icon": "Ícone Feather",
          "Weather Icon": "Ícone de Clima",
          Buttons: "Botões",
          "Default Style": "Estilo Padrão",
          "Flat Style": "Estilo Plano",
          "Edge Style": "Estilo em Destaque",
          "Raised Style": "Estilo em Relevo",
          "Button Group": "Grupo de Botões",
          Charts: "Gráficos",
          "Apex Chart": "Gráfico Apex",
          "Google Chart": "Gráfico do Google",
          "Chart JS Chart": "Gráfico Chart JS",
          Pages: "Páginas",
          "Sample Page": "Página de Exemplo",
          Others: "Outros",
          "Error Pages": "Páginas de Erro",
          "Error 400": "Erro 400",
          "Error 401": "Erro 401",
          "Error 403": "Erro 403",
          "Error 404": "Erro 404",
          "Error 500": "Erro 500",
          "Error 503": "Erro 503",
          "Login Simple": "Login Simples",
          "Login with Bg Image": "Login com Imagem de Fundo",
          "Login With Image Two": "Login com Duas Imagens",
          "Login With Validation": "Login com Validação",
          "Login With Tooltip": "Login com Dica de Ferramenta",
          "Login With Sweetalert": "Login com SweetAlert",
          "Register Simple": "Registro Simples",
          "Register With Bg Image": "Registro com Imagem de Fundo",
          "Register With Bg Two": "Registro com Duas Imagens de Fundo",
          "Register Wizard": "Assistente de Registro",
          "Unlock User": "Desbloquear Usuário",
          "Forget Password": "Esqueci a Senha",
          "Reset Password": "Redefinir Senha",
          Maintenance: "Manutenção",
          "Coming Simple": "Em Breve - Simples",
          "Coming With Bg Video": "Em Breve com Vídeo de Fundo",
          "Coming With Bg Image": "Em Breve com Imagem de Fundo",
          Miscellaneous: "Diversos",
          Gallery: "Galeria",
          "Gallery Grids": "Grade de Galeria",
          "Gallery Grid Desc": "Descrição da Grade de Galeria",
          "Masonry Gallery": "Galeria em Alvenaria",
          "Masonry With Desc": "Alvenaria com Descrição",
          "Hover Effect": "Efeito de Passagem",
          Blog: "Blog",
          "Blog Details": "Detalhes do Blog",
          "Blog Single": "Postagem de Blog Única",
          "Add Post": "Adicionar Postagem",
          FAQ: "Perguntas Frequentes",
          "Job Search": "Busca de Empregos",
          "Cards View": "Visualização em Cartões",
          "List View": "Visualização em Lista",
          "Job Detail": "Detalhe do Emprego",
          Apply: "Aplicar",
          Learning: "Aprendizado",
          "Learning List": "Lista de Aprendizado",
          "Detailed Course": "Curso Detalhado",
          Map: "Mapa",
          "Google Map": "Mapa do Google",
          "Leaflet Map": "Mapa Leaflet",
          Editor: "Editor",
          "CK Editor": "Editor CK",
          "MDE Editor": "Editor MDE",
          "ACE Editor": "Editor ACE",
          Knowledgebase: "Base de Conhecimento",
          "Ui-Kits": "Kits de Interface de Usuário.",
          SupportTicket: "Ticket de Suporte",
          Authentication: "Autenticação",
          "Login With Bg Image": "Login com Imagem de Fundo",
          Pinned: "Fixado",
          "Coming Soon": "Em Breve",
        },
      },
    },
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
